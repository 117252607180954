import React from "react";
import { Layout } from "../components/Layout";
import { HeaderBuffer } from "../components/Layout/Header";

const GivePage = () => {
  return (
    <Layout>
      <main className="min-h-[80vh]">
        <div className="bg-cyan-950">
          <HeaderBuffer />
        </div>
        <div className="w-full px-8 py-16 md:px-16 md:py-16 bg-cyan-900">
          <h1 className="text-white text-2xl text-left mb-4">Give</h1>
          <h2 className="text-white text-5xl font-serif text-left">
            It is more blessed to give than to receive
          </h2>
        </div>
        <div className="p-8 md:p-16 flex flex-col space-y-8">
          <div className=" text-cyan-900 text-xl">
            We appreciate your prayers first and foremost. That's the primary
            way you can help us! If you are so led, you can also support us by
            giving to Wellspring's ministry. There are 3 ways to give.
          </div>
          <div className="p-8 bg-sky-900 rounded-2xl max-w-2xl">
            <h4 className="text-white text-2xl font-serif font-bold text-left mb-4">
              Online
            </h4>
            <div className="text-white text-lg font-light">
              Click the button below to donate through a secure website. Note:
              Please consider using the Bank Account payment method to
              minimize fees.
            </div>
            <div className="flex justify-left">
              <a
                href="https://give.tithe.ly/?formId=e4331019-1987-41a8-9939-3fed5e393ad4"
                target="_blank"
                role="button"
                className="text-sm sm:text-lg transition-colors bg-white hover:bg-gray-200 text-sky-900 px-8 py-3 rounded-full mt-4"
              >
                Give Online
              </a>
            </div>
          </div>
          <div className="p-8 bg-sky-900 rounded-2xl max-w-2xl">
            <h4 className="text-white text-2xl font-serif font-bold text-left mb-4">
              By Mail
            </h4>
            <div className="text-white text-lg font-light">
              You can mail a check to the church office at{" "}
              <span className="font-bold">
                PO Box 208, 2212 W. State St., Alliance, OH 44601.
              </span>{" "}
              Please make your check out to{" "}
              <span className="font-bold">Wellspring Bible Church</span>.
            </div>
          </div>
          <div className="p-8 bg-sky-900 rounded-2xl max-w-2xl">
            <h4 className="text-white text-2xl font-serif font-bold text-left mb-4">
              In Person
            </h4>
            <div className="text-white text-lg font-light">
              Join us for Sunday morning worship and you can drop your gift off
              in the offering box in the foyer.
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default GivePage;
